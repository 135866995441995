import Arthur from "../images/Arthur.png"
import Mary from "../images/Mary.png"
import Billy from "../images/Billy.png"
import Kieran from "../images/Kieran.png"

export const staff=[{
    name: "Arthur",
    role: "Manager",
    image: Arthur
},{
    name: "Kieran",
    role: "Barista",
    image: Kieran
},{
    name: "Billy",
    role: "Barista",
    image: Billy
},{
    name: "Mary",
    role: "Cashier",
    image: Mary
},]